import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
//import Link from '@material-ui/core/Link';
import { API, Auth } from 'aws-amplify';

import Config from "../../AppConfig";
import {getAccount, getSiteSetting} from "../../graphql/queries";
import { verifyAccount, createTest } from "../../graphql/mutations";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {toast} from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PDFViewer from 'pdf-viewer-reactjs'
import {Route} from "react-router-dom";
import DynamicPage from "../pages/DynamicPage";


import 'react-confirm-alert/src/react-confirm-alert.css';

const styles = (theme => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarItem: {
    flex: 1,
  },
  toolbarItem2: {
    marginRight:"10px",
  },
  toolbarTitle : {
    fontFamily: 'ALK Katerina,sans-serif',
    fontSize: '1em'
  },
  button: {

  },
  icon:{
      height : "20px",
      marginRight: "4px",
      float: "left"
  },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        textAlign: "center"
    },
    submitWrapper: {
        margin: theme.spacing(3, 0, 2),
        textAlign: "center"
    },
    submit: {
        width : "42%"
    },
    start: {
        width : "50%",
        margin: "0 auto",
        fontSize: "24px"
    }
}));

export class Profile  extends React.Component {



    constructor(props){
        super(props);
        this.state={
            content: "",
            userCode : "",
            firstName : "",
            userName: "",
            paid : null,
            dynVariables : {},
            grade : "2",
            tabs : [],
            isReady : false,
            isVerified : false,
            loading : true,
            buttonLabel : "",
            buttonDisabled : true,
            buttonVisible : false
        }
        Auth.currentAuthenticatedUser().then(user=>{
            this.setState({"userName":user.username})
            this.setState({"isReady":true});
        }).catch(err => {
            console.log(err);
            window.location.href="/login"
        });
        this.logout = this.logout.bind(this);
      }

      logout() {
        Auth.signOut().then((user)=>{this.setState({"userName":""});window.location.href='/';});
      }

    async componentDidMount() {
        try {
          const account = await API.graphql({query:getAccount,variables:{},authMode:"AMAZON_COGNITO_USER_POOLS"});


          let userAccount = account.data.getAccount;
          if(userAccount){

              let testResults = userAccount.testResults && userAccount.testResults.items ? userAccount.testResults.items : [];
              let dynVariables = {};
              for(let i=0;i<testResults.length;i++){
                let tRes = testResults[i];
                let tRef = tRes.testReference;
                let tExes = tRes.exercises;
                for(let j=0;j<tExes.length;j++){
                  let tExe = tExes[j];
                  dynVariables[""+tRef+"_e"+(j+1).toString()+"_"]=tExes[j].name;
                  dynVariables[""+tRef+"_x"+(j+1).toString()+"_"]=tExes[j].score;
                  dynVariables[""+tRef+"_total_"]=tRes.total;
                }
              }

              this.setState({"dynVariables":dynVariables});

              this.setState({"firstName":userAccount.firstName});
              this.setState({"paid":userAccount.paid});
              this.setState({"grade":userAccount.grade});
              this.setState({"tests":userAccount.tests && userAccount.tests.items ? userAccount.tests.items : []});
              if(userAccount.isConfirmed)
                this.setState({isVerified: true});
          }else{
              //TODO remove this
              window.location.href = "/edit-profile";
          }
        }catch (e) {
            console.log("Error GetAccount: ",e)
        }

        try {
            const rawData = await API.graphql({query:getSiteSetting,variables:{"id":"profile"},authMode:"API_KEY"});

            let json = JSON.parse(rawData.data.getSiteSetting.value);

            for(let key in this.state.dynVariables){
              json.content = json.content.replaceAll(key,this.state.dynVariables[key]).replaceAll("null","-");
            }


            this.setState({content: json.content});
            this.setState({tabs : json.tabs})
            this.setState({quiz : json.quiz ? json.quiz : {}})

            if(this.state.quiz && this.state.quiz.id && this.state.paid){
              //test
              this.setState({"buttonVisible":true});
              let currentTest = this.state.tests.filter((t) => t.testReference == this.state.quiz.id );
              let isDone = this.state.tests.filter((t) => t.testReference == this.state.quiz.id + "_done");
              if(currentTest && currentTest[0]){
                let currentTime = new Date(currentTest[0].currentTime).getTime();
                let startTime = new Date(currentTest[0].startedAt).getTime();
                let timeElapsed = Math.floor((currentTime - startTime)/1000)
                let quizDuration = parseInt(this.state.quiz.duration);
                if(quizDuration > timeElapsed){
                  this.setState({"buttonLabel":this.state.quiz.labelContinue});
                  this.setState({"buttonDisabled":false});
                }else{
                  this.setState({"buttonLabel":this.state.quiz.labelFinished});
                  this.setState({"buttonDisabled":true});
                }
              }else{
                  this.setState({"buttonLabel":this.state.quiz.labelStart});
                  this.setState({"buttonDisabled":false});
                }
                if(isDone && isDone.length){
                  this.setState({"buttonLabel":this.state.quiz.labelDone});
                  this.setState({"buttonDisabled":true});
                }

          }else{
            this.setState({"buttonVisible":false});
          }






            this.submit = () => {
            confirmAlert({
              title: this.state.quiz.warningTitle,
              message: this.state.quiz.warning,
              buttons: [
                {
                  label: 'დიახ',
                  onClick: async function(){
                    try {
                        let test = await API.graphql({query:createTest,variables:{input: {testReference : json.quiz.id || "unknown"}},authMode:"AMAZON_COGNITO_USER_POOLS"});
                        console.log('Success confirmation');
                        toast.success("ტესტი წარმატებით შეიქმნა");
                        document.location.href="/quiz/" + json.quiz.id || "unknown";
                        //window.location.href = "/profile"
                    }catch (e) {
                        toast.error("მოხდა შეცდომა, ტესტის დაწყების დროს!");
                        //document.location.href="/quiz/" + json.quiz.id;
                        console.log(e);
                    }


                  }
                },
                {
                  label: 'არა',
                  onClick: () => console.log('Click No')
                }
              ]
            });
          };


        } catch (err) {
            console.log('error fetching settings...', err)
        }
        this.setState({loading: false});
      }

    inputChange(state,value){
        this.setState({
            [state]: value
        });
    }

    handleSubmit = async  (event) => {
        event.preventDefault();


        if (this.state.userCode.length !== 4) {
            alert('შეიყვანეთ 4-ნიშნა კოდი');
            return;
        }
        let obj = {
            code: this.state.userCode
        };
        try {
             let verify = await API.graphql({query:verifyAccount,variables:obj,authMode:"AMAZON_COGNITO_USER_POOLS"});
            this.setState({"isVerified":true});
            console.log('Success confirmation');
            toast.success("რეგისტრაცია წარმატებით დასრულდა. გისურვებთ წარმატებას!")
            //window.location.href = "/profile"
        }catch (e) {
            toast.error("მოხდა შეცდომა, გთხოვთ სცადოთ ხელახლა!");
            console.log(e);
        }
    }




    render(){
        const { classes, history } = this.props;
        const {isReady} = this.state;
        let body = <React.Fragment>
            <Toolbar className={`${classes.toolbar} prof-header`}>
                <div className="header-holder">
                <div className="profile-header">
                {this.state.isVerified ? <></> : <div className="overlay" ref={this.overlay}></div>}
            <Box className={classes.toolbarItem2}>
            {/* <img src="/assets/qr-code.png" className={classes.icon} alt="qr"/> */}
              <Typography
                  variant="body1"
                  color="inherit"
                  align="left"
                  noWrap
                  className={classes.toolbarTitle}
              >
                {Config.profile.codeLabel + this.state.userName}
              </Typography>
              </Box>
                <Box><Typography>
                    {this.state.firstName.length ? Config.main.greeting + " " + this.state.firstName + " (კლასი: " + this.state.grade + ")" : ""} {this.state.paid ? Config.main.isPaid : this.state.paid === false ? Config.main.notPaid : ''} 
                </Typography>
                </Box>
            </div>

             {/* <Link  className={classes.toolbarItem2} href="/payment">
              <img src="/assets/payment-method.png" className={classes.icon} alt="payment method"/>
              <Typography
                  variant="body1"
                  color="inherit"
                  align="left"
                  noWrap
                  className={classes.toolbarTitle}
              >
                {Config.profile.payLabel}
              </Typography>
              </Link>*/}
              <div className="profile-header">
              {/* <Box flexGrow={1}></Box> */}
                <Box className="buttonOverlay">
                    {this.state.isVerified ? <></> : <div className="overlay" ref={this.overlay}></div>}
                    <Button variant="outlined" size="small" className={classes.button} onClick={(event) => {
                        history.push("/payment")
                    }}><img src="/assets/payment-method.png" className={classes.icon} alt="payment"/><span className="profile-menu-text">{Config.profile.payLabel}</span></Button>
                </Box>
              <Box>
                  <Button variant="outlined" size="small" className={classes.button} onClick={(event) => {
                    history.push("/edit-profile")
                }}><img src="/assets/settings-gears.png" className={classes.icon} alt="settings"/><span className="profile-menu-text">{Config.profile.editProfileLabel}</span></Button>
                </Box>
                <Box>
                  <Button variant="outlined" size="small" onClick={this.logout}><img src="/assets/logout.png" className={classes.icon} alt="exit"/><span className="profile-menu-text">{Config.main.logoutLabel}</span></Button>
                </Box>
              </div>

                </div>

            </Toolbar>
            <main>
                {!this.state.loading ? (!this.state.isVerified ? (
                    <Container component="main" maxWidth="lg">

                        <CssBaseline />
                        <div className={classes.paper}>
                            <form className={classes.form} onSubmit={this.handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography
                                            variant="body1"
                                            color="inherit"
                                            align="left"
                                            noWrap
                                            style={{"width":"42%","margin":"0 auto", "whiteSpace" : "normal"}}
                                        >
                                            დასტურის კოდი გამოიგზავნა თქვენ მიერ მითითებულ მობილურის ნომერზე და ელ. ფოსტის მისამართზე.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            autoComplete="userCode"
                                            name="userCode"
                                            variant="outlined"
                                            required
                                            style={{"width":"42%"}}
                                            value={this.state.userCode}
                                            onChange={e => this.inputChange("userCode",e.target.value)}
                                            id="userCode"
                                            label="კოდი"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Typography
                                            variant="body1"
                                            color="inherit"
                                            align="left"
                                            noWrap
                                            style={{"width":"42%","margin":"0 auto", "whiteSpace" : "normal"}}
                                        >
                                            კოდის არ მიღების შემთხვევაში, დაგვიკავშირდით ნომერზე: +995544442788 ან გამოგვიგზავნეთ შეტყობინება შემდეგ ელ.ფოსტის მისამართზე: info@kenguru.ge
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Typography className={classes.submitWrapper} >

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        დადასტურება
                                    </Button>
                                </Typography>
                            </form>
                        </div>
                    </Container>
                ):
                (
                <React.Fragment>
					<Typography dangerouslySetInnerHTML={{__html:  this.state.content.replaceAll('_username_',this.state.userName)}}></Typography>
                    { this.state.buttonVisible ?
                    <Typography className={classes.submitWrapper} >
                      <Button
                          onClick={this.submit}
                          variant="contained"
                          color="primary"
                          className={classes.start}
                          disabled={this.state.buttonDisabled}
                      >{this.state.buttonLabel}</Button>
                    </Typography>
                    : null }
                    <div style={{"width":"100%","margin":"0 auto","textAlign":"center","maxWidth":"968px","maxHeight":"900px","overflow":"hidden"}}>
                    <Tabs>
                        <TabList>
                            {this.state.tabs.map((tab,index) => (
                                <Tab key={index}>{tab.title}</Tab>
                            ))}
                        </TabList>
                        {this.state.tabs.map((tab,index) => (
                            <TabPanel key={index}>
                                <h2>{tab.text}</h2>
                                <PDFViewer
                                    document={{
                                        url: tab.url.replaceAll('_grade_',this.state.grade).replaceAll('_username_',this.state.userName),
                                    }}
                                    scaleStep={0.1}
                                    hideZoom
                                    hideRotation
                                    navbarOnTop
                                />
                            </TabPanel>
                        ))}
                    </Tabs>
                    </div>
                </React.Fragment>

                )):<></>}
            </main>
          </React.Fragment>;
        if(!isReady){
            body = null;
        }
        return (<>{body}</>);
    }
}
Profile.propTypes = {
    classes: PropTypes.object.isRequired,
  };
export default withStyles(styles)(withRouter(Profile));
